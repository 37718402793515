import { atom } from 'recoil';

const loadingOverlayAtom = new atom({
    key: 'loadingOverlayAtom',
    default: {
        enabled: false,
        message: ''
    }
});

export default loadingOverlayAtom;