
const url = 'api/order';

// Utility service object for connection with the 'api/order' endpoint.
const orderService = {
    // Submits an order to api/order
    // params: orderinfo - a PostOrderRequest object containing the information
    //                     needed to post an order.
    // returns: a PostOrderResponse object.
    submitOrder: async (orderinfo) => {
        // post the request
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(orderinfo)
        });
        //return the response.
        return await response.json();
    }
}

export default orderService;