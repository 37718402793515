import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import {
    Alert,
    Button,
    Grid,
    Snackbar
} from '@mui/material';
import clientCode from '../utilities/getClientCode';
import productService from '../services/ProductService';
import orderService from '../services/OrderService';
import Catalog from './Catalog';
import Instructions from './Instructions';
import ShippingInfo from './ShippingInfo';
import loadingOverlayAtom from '../utilities/loadingOverlayAtom';

// Home Page Component.
export const Home = () => {
    // variables for the shipping information and product information
    const [firstName, setFirstName] = useState(''),
        [lastName, setLastName] = useState(''),
        [company, setCompany] = useState(''),
        [email, setEmail] = useState(''),
        [address1, setAddress1] = useState(''),
        [address2, setAddress2] = useState(''),
        [city, setCity] = useState(''),
        [state, setState] = useState(''),
        [postalCode, setPostalCode] = useState(''),
        [products, setProducts] = useState([]),
        client = clientCode,
        history = useNavigate();

    // variables for form validation
    const productsValid = products.every(x => x.Qty >= 0 && x.Qty < 21) && products.some(x => x.Qty > 0);
    const fnValid = firstName.length > 0;
    const lnValid = lastName.length > 0;
    const companyValid = company.length > 0;
    const emailValid = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email);
    const addressValid = address1.length > 0;
    const cityValid = city.length > 0;
    const stateValid = state.length > 0;
    const pcValid = postalCode.length === 5 || postalCode.length === 10;

    const [loadingOverlayActive, setLoadingOverlayActive] = useRecoilState(loadingOverlayAtom);
    // State variable to hold any errors returned when the Submit button is clicked
    const [toastMessage, setToastMessage] = useState(null);
    // Variable to toggle the snackbar open or closed
    const showSnackBar = Boolean(toastMessage);

    // the actual submit click function
    const realSubmitOnClick = async () => {
        //disables the submit click function for 1 second.
        submitOnClick = fakeSubmitOnClick;
        setTimeout(() => { submitOnClick = realSubmitOnClick }, 1000);
        setLoadingOverlayActive({enabled:true, message:'Submitting Order...'})
        // Create a PostOrderRequest object from the state variables.
        var postOrderReq = {
            Order: {
                Client: client,
                FirstName: firstName,
                LastName: lastName,
                Company: company,
                EmailAddress: email,
                Address1: address1,
                Address2: address2,
                City: city,
                State: state,
                PostalCode: postalCode
            },
            ProductQuantities: products.filter(x => x.Qty > 0).map(x => { return { ProductID: x.ID, Quantity: x.Qty } })
        }

        // submit the order.
        var resp = await orderService.submitOrder(postOrderReq);
        setLoadingOverlayActive({ enabled: false, message: '' });
        // if successfull push to thankyou message. otherwise display an error message
        if (resp.Success) {
            const confnum = resp.ConfirmationNumber;
            history('/thankyou?conf=' + confnum);
        } else {
            setToastMessage(resp.Message);
        }
    }
    // fake click event for preventing multi clicks.
    const fakeSubmitOnClick = () => { }
    let submitOnClick = realSubmitOnClick;

    // useEffect to load products from the database.
    useEffect(() => {
        setLoadingOverlayActive({ enabled: true, message: 'Loading Products...' });
        // loads products from the database.
        const loadProducts = async () => {
            const products = await productService.loadProducts(client);
            setProducts(products);
            setLoadingOverlayActive({ enabled: false, message: '' });
        };

        loadProducts();

    }, []);

    // utility object for passing props into 
    // the ShippingInfo componeent.
    const siProps = {
        firstName, setFirstName, fnValid,
        lastName, setLastName, lnValid,
        company, setCompany, companyValid,
        email, setEmail, emailValid,
        address1, setAddress1, addressValid,
        address2, setAddress2,
        city, setCity, cityValid,
        state, setState, stateValid,
        postalCode, setPostalCode, pcValid
    };

    // Function to close the snackbar
    function handleClose() {
        setToastMessage(null);
    }

    const getYour = () => {
       // if (client === 'mondelez')
            return "your";

        //if (client === 'vizient')
        //    return "Vizient";

        //if (client === 'premier')
        //    return "Premier"

        //if (client === 'entegra')
        //    return "Entegra"
    }

    return (
        <div>
            <h1>Order Custom Rise & Thrive Point-of-Sale</h1>
            <p>Help {getYour()} customers Rise &amp; Thrive with belVita Breakfast Biscuits&mdash;America's #1 breakfast biscuit.* Simply follow the steps below to select the quantities of these custom point-of-sale materials. Once you receive them, place them in high-traffic areas to ensure your customers know you're a reliable source of steady morning energy.</p>
            <Instructions />
            <Catalog products={products} setProducts={setProducts} />
            <ShippingInfo {...siProps} />
            <Grid container spacing={2} >

                <Grid item xs={12}>
                    <div style={{ textAlign: 'center', margin: '20px 0 10px 0' }}>
                        <Button variant='contained' style={{position:'inherit'} }
                            disabled={!(productsValid && fnValid && fnValid && companyValid && emailValid && addressValid && cityValid && stateValid && pcValid)}
                            onClick={submitOnClick}>Submit</Button>
                    </div>
                </Grid>
            </Grid>
            {
                showSnackBar &&
                <Snackbar
                    open={showSnackBar}
                    autoHideDuration={6000}
                    onClose={handleClose}
                >
                    <Alert onClose={handleClose} severity="error">
                        {toastMessage}
                    </Alert>
                </Snackbar>
            }
        </div>
    );
};
