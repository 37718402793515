import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import { Layout } from './components/Layout';
import { Home } from './components/Home';
import ThankYou from './components/ThankYou';
import { Helmet } from "react-helmet";
import clientCode from './utilities/getClientCode';

import './custom.css'

import { ThemeProvider, createTheme } from '@mui/material/styles';

const theme = createTheme({
    typography: {
        allVariants: {
            fontFamily: 'BwSurco-Regular, sans-serif;',
            // textTransform: 'none',
            //fontSize: 16,
        },
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: ({ ownerState }) => ({
                    ...(ownerState.variant === 'contained' &&
                        ownerState.color === 'primary' && {
                        backgroundColor: '#EF6A00',
                        borderRadius: 0,
                        padding: '20px 45px',
                        fontWeight: 'bold',
                        fontSize: '1.4rem',
                        '&:hover': {
                            background: 'linear-gradient(rgba(0, 0, 0, 0.1),rgba(0, 0, 0, 0.1)), #EF6A00'
                        }
                    }),
                }),
            },
        },
        palette: {
            primary: {
                main: '#EF6A00'
            }
        }
    }
});

const App = () => {

    return (
        <>
            <Helmet>
                <script src={'vendor/google/'+clientCode+'-tag-manager.js' }></script>
            </Helmet>
            <RecoilRoot>
                <ThemeProvider theme={theme}>
                    <Layout>
                        <Routes>
                            <Route exact path='/' element={<Home />} />
                            <Route path='/thankyou' element={<ThankYou />} />
                        </Routes>
                    </Layout>
                </ThemeProvider>
            </RecoilRoot>
        </>
    );
}

export default App;