

const productService = {
    loadProducts: async (client) => {
        //uncomment when products are added to db.
        let products = await (await fetch('api/products/' + client)).json();
        products = products.map(x => { return { ...x, Qty: 0 } });

        //ficticious product list for development.
        //comment out when products are added to a db.
        //const products = [
        //    {
        //        ID: 1,
        //        Name: "Table Tent",
        //        ImageLocation: "https://via.placeholder.com/150x200",
        //        MaxQuantity: 20,
        //        Qty: 0
        //    },
        //    {
        //        ID: 2,
        //        Name: "Table Tent",
        //        ImageLocation: "https://via.placeholder.com/150x200",
        //        MaxQuantity: 20,
        //        Qty: 0
        //    },
        //    {
        //        ID: 3,
        //        Name: "Table Tent",
        //        ImageLocation: "https://via.placeholder.com/150x200",
        //        MaxQuantity: 20,
        //        Qty: 0
        //    },
        //    {
        //        ID: 4,
        //        Name: "Table Tent",
        //        ImageLocation: "https://via.placeholder.com/150x200",
        //        MaxQuantity: 20,
        //        Qty: 0
        //    }
        //];
        return products;
    }
};

export default productService;