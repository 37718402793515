import React from 'react';
import { useRecoilValue } from 'recoil';
import { NavMenu } from './NavMenu';
import { Container } from '@mui/material';
import Footer from './Footer';
import LoadingOverlay from '@ronchalant/react-loading-overlay';
import loadingOverlayAtom from '../utilities/loadingOverlayAtom';

export const Layout = (props) => {
    const loadingOverlayInfo = useRecoilValue(loadingOverlayAtom);
    return (
        <>
            {
                loadingOverlayInfo.enabled &&
                <LoadingOverlay
                    //active={true}
                    active={loadingOverlayInfo.enabled}
                    spinner
                    text={loadingOverlayInfo.message}
                >
                </LoadingOverlay>
            }
            <div style={{minHeight:`calc(100vh - 59px)`}}>
                <NavMenu />
                <Container>
                    {props.children}
                </Container>
            </div>
            <Footer />
           
        </>
    );
};