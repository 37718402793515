

// React Component for displaying the instructions for using the
// order form.
const Instructions = () => {

    return (
        <>
            <h2>How to order:</h2>
            <div>
                <ol>
                    <li>Add quantities to each item below. Max 20 per asset.</li>
                    <li>Fill in your shipping info.</li>
                    <li>Click "Submit."</li>
                </ol>
            </div>
        </>
    );
}

export default Instructions;