import {
    Container,
    Grid
} from '@mui/material';

const barColor = '#EF6A00';

const Footer = () => {
    return (
        <div className='footer' style={{ marginTop: '10px', padding: '10px 0', backgroundColor: barColor }}>
            <Container>
                <Grid container spacing={2}>
                    <Grid item xs={7}>
                        &copy; Mondelēz International Group
                        <div style={{ fontSize: '12px' }}>* Nielsen, xAOC plus Convenience—52 weeks ending 1/2/22</div>
                    </Grid>
                    <Grid item xs={5} container justifyContent='flex-end'>
                        <a href="https://www.sgstorefront.com/privacy.html" target="_blank">Privacy Policy</a>
                    </Grid>
                </Grid>
            </Container>
        </div>
    )
}

export default Footer;