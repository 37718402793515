import { Grid } from '@mui/material'; 
import Product from './Product';

const Catalog = (props) => {
    const products = props.products;
    const setProducts = props.setProducts;


    return (
        <>
            <h2>Catalog</h2>
            <Grid container spacing={2}>
                {
                    products.length < 1 &&
                    <Grid item xs={12}> Loading Products... </Grid>
                }
                {
                    products.length > 0 &&
                    products.map((p, i) => {
                        return (
                            <Grid key={'product-' + p.ID} item xs={12} md={6} lg={3}>
                                <Product product={p} products={products} setProducts={setProducts} index={i} />
                            </Grid>
                        );
                    })
                }
            </Grid>
        </>
    );

}

export default Catalog;