import React, { useState } from 'react';
import {
    Container,
    Grid
} from '@mui/material';
import { Link } from 'react-router-dom';
import './NavMenu.css';
import Client from '../utilities/getClientCode';
import mdlzLogo from '../images/mondelez_logo.png';
import riseAndThrive from '../images/belVitaRiseAndThrive_LockUp_r1.png';
import entegraLogo from '../images/entegra-logo-final.png';
import premierLogo from '../images/LockUp_HiRes_contracted_supplier_309.png';
import vizientLogo from '../images/vzt_awardsupp_r_rgb_orn_pos.png';

const barColor = '#EF6A00';

export const NavMenu = () => {

    const getImage = () => {
        if (Client === 'mondelez') 
            return mdlzLogo;

        if (Client === 'vizient') 
            return vizientLogo;

        if (Client === 'premier')
            return premierLogo

        if (Client === 'entegra')
            return entegraLogo
    }

    const getContractID = () => {
        if (Client === 'vizient')
            return 'Contract ID: FD3512';

        if (Client === 'premier')
            return 'Contract ID: PP-DI-1923';

        return '';
    }

    return (
        <header>
            <Container>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <img src={getImage()} style={{ display: 'inline-block', maxHeight:'80px', maxWidth:'100%' } } />
                    </Grid>
                    <Grid item xs={6} container direction="column" alignItems="flex-end" justifyContent="center">
                        <span style={{ fontFamily: 'Montserrat'} }>{getContractID()}</span>
                        </Grid>
                </Grid>
            </Container>
            <div style={{ width: '100%', height: '20px', backgroundColor: barColor, marginTop:'10px',marginBottom:'10px' }}></div>
            <Container>
                <div style={{ textAlign: 'center' } }>
                    <img src={riseAndThrive} style={{ maxWidth: '80%', maxHeight:'400px' } } />
                </div>
            </Container>
        </header>
    );
};