import { useState } from 'react';
import {
    Grid,
    MenuItem,
    TextField
} from '@mui/material';
import states from 'states-us';


const sxObj = {
    "& label.Mui-focused": {
        color: "#ef6a00"
    },
    "& .MuiOutlinedInput-root.Mui-focused": {
        "& > fieldset": {
            borderColor: "#ef6a00"
        }
    }
};

const ShippingInfo = (props) => {
    const stateList = states.filter((x) => (
        x.name !== 'American Samoa' &&
        x.name !== 'Federated States Of Micronesia' &&
        x.name !== 'Guam' &&
        x.name !== 'Marshall Islands' &&
        x.name !== 'Northern Mariana Islands' &&
        x.name !== 'Palau' &&
        x.name !== 'Puerto Rico' &&
        x.name !== 'Virgin Islands'
    ));

    const [fnVis, setFnVis] = useState(false);
    const [lnVis, setLnVis] = useState(false);
    const [companyVis, setCompanyVis] = useState(false);
    const [emailVis, setEmailVis] = useState(false);
    const [address1Vis, setAddress1Vis] = useState(false);
    const [cityVis, setCityVis] = useState(false);
    const [stateVis, setStateVis] = useState(false);
    const [pcVis, setPcVis] = useState(false);

    return (
        <>
            <h2>Shipping Information</h2>
            <Grid container spacing={4}>
                <Grid item xs={12} md={6}>
                    <TextField sx={sxObj} variant='outlined' InputLabelProps={{ shrink: true }} error={!props.fnValid && fnVis} required label='First Name'
                        value={props.firstName} onChange={(e) => { props.setFirstName(e.target.value); }} onFocus={() => { setFnVis(true) }} fullWidth />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField sx={sxObj} variant='outlined' InputLabelProps={{ shrink: true }} error={!props.lnValid && lnVis} required label='Last Name'
                        value={props.lastName} onChange={(e) => { props.setLastName(e.target.value); }} onFocus={() => { setLnVis(true) }} fullWidth />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField sx={sxObj} variant='outlined' InputLabelProps={{ shrink: true }} error={!props.companyValid && companyVis} required label='Company'
                        value={props.company} onChange={(e) => { props.setCompany(e.target.value); }} onFocus={() => { setCompanyVis(true) }} fullWidth />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField sx={sxObj} variant='outlined' InputLabelProps={{ shrink: true }} error={!props.emailValid && emailVis} required label='Email Address'
                        value={props.email} onChange={(e) => { props.setEmail(e.target.value); }} onFocus={() => { setEmailVis(true) }} fullWidth />
                </Grid>
                <Grid item xs={12}>
                    <TextField sx={sxObj} sx={sxObj} variant='outlined' InputLabelProps={{ shrink: true }} error={!props.addressValid && address1Vis} required label='Address 1'
                        value={props.address1} onChange={(e) => { props.setAddress1(e.target.value); }} onFocus={() => { setAddress1Vis(true) }} fullWidth />
                </Grid>
                <Grid item xs={12}>
                    <TextField sx={sxObj} variant='outlined' InputLabelProps={{ shrink: true }} label='Address 2' value={props.address2}
                        onChange={(e) => { props.setAddress2(e.target.value); }} fullWidth />
                </Grid>
                <Grid item xs={6} md={4}>
                    <TextField sx={sxObj} variant='outlined' InputLabelProps={{ shrink: true }} error={!props.cityValid && cityVis} required label='City'
                        value={props.city} onChange={(e) => { props.setCity(e.target.value); }} onFocus={() => { setCityVis(true) }} fullWidth />
                </Grid>
                <Grid item xs={6} md={4}>
                    <TextField sx={sxObj} variant='outlined' InputLabelProps={{ shrink: true }} error={!props.stateValid && stateVis} required label='State'
                        value={props.state} onChange={(e) => { props.setState(e.target.value); }} onFocus={() => { setStateVis(true) }} fullWidth select>
                        {
                            stateList.map((x) => {
                                return (<MenuItem id={x.name} key={x.name} value={x.abbreviation}>{x.name}</MenuItem>);
                            })
                        }
                    </TextField>
                </Grid>
                <Grid item xs={6} md={4}>
                    <TextField sx={sxObj} variant='outlined' InputLabelProps={{ shrink: true }} error={!props.pcValid && pcVis} required label='Postal Code'
                        value={props.postalCode} onChange={(e) => { props.setPostalCode(e.target.value); }} onFocus={() => { setPcVis(true) }} fullWidth />
                </Grid>
            </Grid>
        </>
    );
};

export default ShippingInfo;