
const mdlzurl = '';
const vizienturl = 'risethrive-v';
const premierurl = 'risethrive-p';
const entegraurl = 'risethrive-e';

const getClientCode = () => {
    const url = window.location.href.toLowerCase();
    if (url.includes(vizienturl)) {
        return 'vizient';
    } else if (url.includes(premierurl)) {
        return 'premier';
    } else if (url.includes(entegraurl)) {
        return 'entegra';
    } else {
        return 'mondelez';
    }
};

const clientCode = getClientCode();

export default clientCode;

