import React from 'react';
import {
    Card, CardContent,
    TextField
} from '@mui/material'

const sxObj = {
    "& label.Mui-focused": {
        color: "#ef6a00"
    },
    "& .MuiOutlinedInput-root.Mui-focused": {
        "& > fieldset": {
            borderColor: "#ef6a00"
        }
    },
    input: { textAlign: 'center' }, width: '50%'
};

// Name: Product
// Description: React Component for Displaying Product Information
const Product = (props) => {
    const p = props.product;

    // Updates the Qty field of the product and then persists it to state.
    const updateProduct = (e) => {
        const newProds = [...props.products];

        // the next few lines interperate the value of the input change.
        let newQuan = e.target.value === '' ? '' : parseInt(e.target.value);
        if (isNaN(newQuan)) {
            newQuan = '';
        } else if (newQuan > p.MaxQuantity) { 
            newQuan = p.MaxQuantity;
        }

        // Sets the quantity and updates state.
        newProds[props.index].Qty = newQuan;
        props.setProducts(newProds);
    };
    return (
        <Card sx={{ minWidth: '100%', textAlign: 'center' }}>
            <CardContent>
                <div style={{minHeight:'205px'} }><img src={p.ImageLocation} style={{maxHeight:'200px', maxWidth:'90%'} } /></div>
                <h3 style={{marginBottom: '0'} }>{p.Name}</h3>
                <div style={{margin:'5px 0'}}>{p.Dimensions }</div>
                <div>
                    <TextField
                        sx={sxObj}
                        value={p.Qty}
                        type='number'
                        onChange={updateProduct}
                        InputProps={{
                            inputProps: {
                                min: 1,
                                max: p.MaxQuantity,
                                type: "text",
                                pattern: "[0-9]*"

                            }
                        }}
                    />
                </div>
            </CardContent>
        </Card> 
    );
}

export default Product;