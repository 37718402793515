import React from 'react';

const ThankYou = (props) => {
    const queryParams = Object.fromEntries(new URLSearchParams(window.location.search));
    const confnumber = queryParams.conf;
    return (
        <div style={{textAlign:'center'} }>
            <h2>Thanks For Your Order!</h2>
            <p>Please allow 2 - 4 weeks for printing and shipping.</p>
            <p>For more information, please contact your Mondelēz Sales Representative.</p>
        </div>
    )

};


export default ThankYou;